import { useContext, createContext, useEffect } from "react";
import { createTelemetry } from "../helpers/tracking";
import { useAuth } from "./auth.context";
import { useTeams } from "../hooks/useTeams";

const EVENT_TRACKER_CONFIG = [{ name: "amplitude" }, { name: "indicative" }, { name: "matomo" }];

const TelemetryContext = createContext({ telemetry: null });

const TelemetryProvider = (props) => {
  const { user, loggedIn } = useAuth();
  const telemetry = createTelemetry(EVENT_TRACKER_CONFIG);
  const [{ teamsContext, isInitializing }] = useTeams();

  useEffect(() => {
    if (loggedIn && !isInitializing) {
      telemetry.identify(teamsContext.user.id, {
        value1Type: "Plan ID",
        value1: user.organization.id,
        azId: teamsContext.user.id,
      });
    }
  }, [loggedIn, user, telemetry, isInitializing, teamsContext]);

  return (
    <TelemetryContext.Provider
      value={{
        telemetry: telemetry,
      }}
      {...props}
    />
  );
};

const useTelemetry = () => useContext(TelemetryContext);

export { TelemetryProvider, useTelemetry };
